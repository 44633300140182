import { Controller } from "stimulus"
import videojs from "video.js"
import "video.js/dist/video-js.min.css"
require("@silvermine/videojs-quality-selector")(videojs)
import "@silvermine/videojs-quality-selector/dist/css/quality-selector.css"

export default class extends Controller {

  static values = {
    type: String,
    label: String,
    qualitySelectorControl: Boolean
  }

  initialize() {
    this.element.classList.add("video-js")

    this.videojs = videojs(this.element, {
      preload: "metadata",
      // controls: true,
      fluid: true,
      audioOnlyMode: this.typeValue === "audio",
      responsive: true,
      bigPlayButton: false,
      languages: this.languages,
      controlBar: {
        children: this.controls
      }
    })


    if (this.labelValue) {
      this.videojs.el().setAttribute("data-video-label-value", this.labelValue)
    }
  }

  typeValueChanged(value) {
    if (value) {
      this.element.classList.add(`--${value}`)
    }
  }

  get languages() {
    return {
      en: {
        "{1} is loading.": "Loading"
      }
    }
  }

  get controls() {

    const controls = [
      "playToggle",
      "progressControl",
      "volumePanel",
      "fullscreenToggle"
    ]

    if (this.qualitySelectorControlValue) {
      controls.push("QualitySelector")
    }

    return controls
  }
}
